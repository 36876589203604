html {
  scroll-behavior: smooth;
}

* {
    -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', Manrope
    monospace;
}

div {
	box-sizing: border-box;
}
a {
	text-decoration: none;
	box-sizing: border-box;
  color: unset;
}

input {
  padding: 20px;
  width: 100%;
  font-size: 20px;
  border-radius: 5px;
  margin: 20px;
}

button {
  padding: 20px;
  font-size: 20px;
  border-radius: 5px;
  color: white;
  background-color: black;
}

.flexRow { display: flex; flex-direction: row; }
.flexColumn { display: flex; flex-direction: column; }

.flexCenter { display: flex; justify-content: center; align-items: center; text-align: center; }
.flexStart { display: flex;	justify-content: flex-start; align-items: center; }
.flexEnd { display: flex;	justify-content: flex-end; align-items: center; }
.flexWrap { flex-wrap: wrap }
.flexSpaceEvenly { display: flex; justify-content: space-evenly; align-items: center; text-align: center; }
.flexSpaceBetween { display: flex; justify-content: space-between; align-items: center; text-align: center; }
.fullWidth { width: 100% }
.fillParent { position: absolute; top: 0; right: 0; bottom: 0; left: 0 }

.mailchimpStyle {
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: space-evenly;
  align-items: center;
}

.relative { position: relative; }
.pointer { cursor: pointer; }
.dropShadow { filter: drop-shadow(2px 2px 4px black); }
.dropShadowRight { filter: drop-shadow(1px 0px 4px black); }
.dropShadowLeft { filter: drop-shadow(-1px 0px 4px black); }

/*  Grid Animation */
.sk-grid {
  width: 100px;
  height: 100px;
  /* Cube positions
   * 1 2 3
   * 4 5 6
   * 7 8 9
   */
 }
.sk-grid-cube {
    width: 33.33%;
    height: 33.33%;
    background-color: white;
    float: left;
    animation: sk-grid 1.3s infinite ease-in-out;
}
.sk-grid-cube:nth-child(1) { animation-delay: 0.2s; }
.sk-grid-cube:nth-child(2) { animation-delay: 0.3s; }
.sk-grid-cube:nth-child(3) { animation-delay: 0.4s; }
.sk-grid-cube:nth-child(4) { animation-delay: 0.1s; }
.sk-grid-cube:nth-child(5) { animation-delay: 0.2s; }
.sk-grid-cube:nth-child(6) { animation-delay: 0.3s; }
.sk-grid-cube:nth-child(7) { animation-delay: 0.0s; }
.sk-grid-cube:nth-child(8) { animation-delay: 0.1s; }
.sk-grid-cube:nth-child(9) { animation-delay: 0.2s; }

@keyframes sk-grid {
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1);
  } 35% {
    transform: scale3D(0, 0, 1);
  }
}